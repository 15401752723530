import React from "react";

const ContactModule = () => {

  return (
    <section className="contact-module-container container">
        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="row">
                <div className="col-100 col-small-1-2">
                    <label htmlFor="name" className="form-label">Full Name</label>
                    <input type="text" name="name" className="form-input full-width" id="name" />
                </div>
                <div className="col-100 col-small-1-2">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input type="text" name="email" className="form-input full-width" id="email" />
                </div>
            </div>
            <div className="row">
                <div className="col-100">
                    <label htmlFor="subject" className="form-label">Subject</label>
                    <input type="text" name="subject" className="form-input full-width" id="subject" />
                </div>
            </div>
            <div className="row">
                <div className="col-100">
                    <label htmlFor="message"className="form-label">Message</label>
                    <textarea name="message" className="form-textarea full-width"id="message" rows="6" />
                </div>
            </div>
            <div className="row actions">
                <div className="col-100 text-align-right">
                    <input type="submit" value="Send Message" className="button full-width" />
                </div>
            </div>
        </form>
    </section>
  )
}

export default ContactModule
