import { useStaticQuery, graphql } from "gatsby"
export const  useContactData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesContactYaml {
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
          }
        }
      }
    `
  )
  return data.pagesContactYaml
}