import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeadingModule from "../components/modules/heading-module"
import GoogleMapsModule from "../components/google/google-maps-module"
import ContactModule from "../components/contact-module"
import { useContactData } from "../hooks/use-contact-data"
class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

	const HeadingContainer = () => {
		const { heading } = useContactData();
		return (
			<HeadingModule
				headingText={ heading.headingText }
				subHeadingText={ heading.subHeadingText }
				subHeadingMaxWidth={ heading.subHeadingMaxWidth}
				classes=""
			/>
		)
	}

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact - Niwot Inn &amp; Spa" />
		<div className="contact-page">
			<div className="container">
				<div className="row">
					<HeadingContainer />
				</div>
				<div className="row">
					<div className="col-100 col-small-1-2 google-maps-container">
						<GoogleMapsModule />
					</div>
					<div className="col-100 col-small-1-2 contact-container">
						<ContactModule />
					</div>
				</div>
			</div>
		</div>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
