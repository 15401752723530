import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { mapStyle } from './google-maps-style';

const AnyReactComponent = ({ text }) => {
  return (
    <div>
      {/* {text} */}
      <img className="marker" width="50px" src="/images/map-marker.svg" />
    </div>
  )
};

const handleApiLoaded = (map, maps) => {
    map.setOptions({
        styles: mapStyle,
        disableDefaultUI: true
    })
    var contactContainer = document.querySelector('.contact-container');
    var googleMapsContainer = document.querySelector('.google-maps-container');
    googleMapsContainer.style.height = ( contactContainer.offsetHeight - 20 ) + 'px';
};

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 40.102080,
      lng: -105.170375
    },
    zoom: 18
  };

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAqMIWRhzVSAtT2MGy6KJut_4sAH1OmS9E' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <AnyReactComponent
            lat={40.102190}
            lng={-105.170305}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default SimpleMap;